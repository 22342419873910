.pagiGrid {
  font-size: 14px;
  color: #424242;
}
.pagiHead{
  background-color: #E1F5FE;
}

.pagiHead ion-select{
  --padding-start:4px;
  --padding-end:4px;
}

.pagiHead ion-col{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pagiLine{
  align-items: center;
}

.pagiLine:nth-child(even){
  background-color: #ECEFF1;
}


.pagiLine:nth-child(odd){
  background-color: #FAFAFA;
}

.pagiFoot{
  --background: #E1F5FE;
}
