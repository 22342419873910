.cardTitle{
  padding:16px;
}

.homeGrid{

  padding:0;
}

.homeRow{
  padding:0;
}

.homeCol{
  padding:0;
}